import { useEffect, useState } from 'react'
// import dynamic from 'next/dynamic'
import phone from 'phone'
import { setItem } from '../lib/asyncStorage'
import { logPageView, logSegEvent } from '../lib/analytics'
import Layout from '../components/Layout'
import PageHeaderGoBack from '../components/PageHeaderGoBack'
import BottomButton from '../components/BottomButton'
import Link from 'next/link'
import { Form } from 'react-bootstrap'
import { useCloudFn2, useAuthWithToken } from '../hooks'
import { INDEX_PATH } from '../constants/posse'
import logErr from '../lib/err'
import ReactCodeInput from 'react-code-input'
import firebase from 'firebase/app'

//EXPERIMENTS
const experiment = 'login'
const variant = '1'

const reloadIcon = '/static/landslide/icons/Path.png'
// const ReactCodeInput = dynamic(import('react-code-input'))

const styleProps = {
  inputStyle: {
    fontFamily: 'Poppins',
    margin: '8px',
    width: '48px',
    height: '48px',
    fontSize: '16px',
    paddingLeft: '7px',
    color: '#1D0830',
    outline: 'none',
    borderStyle: 'solid',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderTopWidth: '0px',
    borderBottomWidth: '2px',
    background: 'rgba(0, 0, 0, 0.0001)',
    textAlign: 'center',
  },
}

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState('')
  const [resendMsg, setResendMsg] = useState()
  const [{ pending: pendingSubmit, result: valueSubmit, error: errorSubmit }, loginPhone] = useCloudFn2('loginPhone')
  const [{ pending: pendingVerify, result: valueVerify, error: errorVerify }, verifyLoginPhone] = useCloudFn2('verifyLoginPhone')
  const authWithToken = useAuthWithToken()
  const [smsID, setSmsID] = useState()
  const [smsStatus, setSmsStatus] = useState()

  useEffect(() => {
    logPageView('login')
  }, [])

  useEffect(() => {
    if (!valueSubmit) return
    if (valueSubmit?.result === 'handled') {
      setPhoneNumberSubmitted(true)
    }
  }, [valueSubmit])

  useEffect(() => {
    const asyncHelper = async () => {
      if (!valueVerify) return
      if (valueVerify.result === 'success') {
        console.log('backend login succeeded')
        logSegEvent('Login Verify Succeeded')
        try {
          console.log('attempting firebase auth')
          await authWithToken(valueVerify.authToken)
          console.log('firebase auth succeeded')
          logSegEvent('User Login Succeeded')
        } catch (err) {
          // FIXME the backend says they're in, but Firebase Auth failed ...
          //setErrorAuth('There was a problem logging in.  Please try again')
          console.log('firebase auth failed', err.message)
          logSegEvent('Login Auth Failed')
        }
        // moved storage update to after authWithToken in case it's contributing to:
        // Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.
        await setItem('member', true)
      } else {
        // the backend will either throw an error or return result === 'success'
      }
    }
    asyncHelper()
  }, [valueVerify])

  useEffect(() => {
    if (errorVerify) {
      logSegEvent('Login Verify Failed')
      logErr(errorVerify, 'login verify failed')
    }
  }, [errorVerify])

  useEffect(() => {
    if (!smsID) return

    const onSnap = (snap) => {
      const data = snap.data()
      let status = data.status
      if (data.errorMsg) status += ': ' + data.errorMsg
      console.log('sms status: ', status)
      setSmsStatus(status)
    }
    const unsub = firebase
      .firestore()
      .collection('sms')
      .doc(smsID)
      .onSnapshot(onSnap, (err) => {
        logErr(err, 'SUB sms status failed')
      })
    return () => {
      unsub()
    }
  }, [smsID])

  const parsedPhoneNumber = phone(phoneNumber)
  const isPhoneNumberValid = parsedPhoneNumber.length === 2 && parsedPhoneNumber[1] === 'USA'

  const isValidCode = () => {
    const reg = new RegExp(/^\d+$/) // checks for numerals only
    return reg.test(confirmationCode) && confirmationCode.length === 4
  }

  const handlePhoneNumberSubmit = async (e) => {
    e?.preventDefault()
    e?.stopPropagation()
    logSegEvent('User Login Started')
    const loginData = { phone: parsedPhoneNumber[0] }
    console.log('attempting login', loginData)
    setSmsStatus('queueing')
    const res = await loginPhone(loginData)
    if (res) console.log('login sent', res)
    setSmsID(res.sid)
  }

  const handleConfirmationSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    logSegEvent('User Login Confirmed')
    const confirmData = { token: confirmationCode, phone: parsedPhoneNumber[0] }
    console.log('attempting verify', confirmData)
    verifyLoginPhone(confirmData)
  }

  const handleFormChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const _dontHaveAnAccount = () => {
    return (
      <p className="mt-2 mb-0">
        Don't have an account?{' '}
        <Link href={INDEX_PATH}>
          <button id="join-landslide">Join Landslide</button>
        </Link>
      </p>
    )
  }

  return (
    <Layout title="Login" url="login">
      <div id="login">
        <PageHeaderGoBack title="Login to Landslide" />
        <main>
          {/* Phone number and code input form */}
          {!phoneNumberSubmitted ? (
            <div>
              <Form onSubmit={handlePhoneNumberSubmit}>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="phone" className="custom-label">
                    Your Phone Number
                  </Form.Label>
                  <Form.Control name="phone" placeholder={'Phone Number'} onChange={handleFormChange} type="text" value={phoneNumber} />
                </Form.Group>
              </Form>
              {errorSubmit && <p className="lg bold error text-center mt-3">{errorSubmit.message}</p>}
            </div>
          ) : (
            <div>
              <p className="text-center description">Enter the code you received:</p>
              <div className="d-flex jcc">
                <ReactCodeInput
                  controlId="confirmCode"
                  type="number"
                  value={confirmationCode}
                  fields={4}
                  {...styleProps}
                  onChange={(val) => {
                    setResendMsg()
                    setConfirmationCode(val)
                  }}
                />
              </div>
            </div>
          )}
          {phoneNumberSubmitted && (
            <div>
              <p className="text-center mt-4">
                <img src={reloadIcon} alt="resend code" />
                Didn't get the code?{' '}
                <button
                  className="resend-button"
                  onClick={(e) => {
                    setResendMsg('A new code has been sent.')
                    setConfirmationCode([' ', ' ', ' ', ' '])
                    handlePhoneNumberSubmit(e)
                  }}
                >
                  Resend
                </button>{' '}
              </p>
              {errorVerify && <p className="lg bold error text-center mt-3">{errorVerify.message}</p>}
              {resendMsg && <p className="text-center bold error">{resendMsg}</p>}
              {smsStatus && <p>SMS Status: {smsStatus}</p>}
            </div>
          )}
        </main>
        {/* BottomButton Versions */}
        {!phoneNumberSubmitted ? (
          <BottomButton
            handleClick={handlePhoneNumberSubmit}
            pending={pendingSubmit}
            gtm="gtm-login-btn"
            text="Login"
            disabled={!isPhoneNumberValid}
            below={_dontHaveAnAccount}
          />
        ) : (
          <BottomButton
            handleClick={handleConfirmationSubmit}
            pending={pendingVerify}
            text="Verify"
            gtm="gtm-login-verify-btn"
            disabled={!isValidCode()}
          />
        )}
      </div>
    </Layout>
  )
}
